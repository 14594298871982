<template>
  <v-app>
    <app-header />
    <router-view />
    <notification-bar />
  </v-app>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description app-layout Component Logic
   * @date 24.07.2020
   */
  export default {
    name: "app-layout",
    components: {
      "app-header": () => import("@/layouts/app-header")
    },
    data: () => ({
      sidebar: false
    }),
    watch: {},
    methods: {
      navigate() {
        this.$router.push({ name: "dashboard" });
      },
      onClickLogout() {
        this.$store.dispatch("auth/LOGOUT");
      }
    }
  };
</script>
